var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "QRcode" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v("扫码/长按添加：官方企业微信")
      ]),
      _c("div", [
        _vm._v(" 了解更多带病保险知识"),
        _c("br"),
        _vm._v(" 加入病友互动群交流经验"),
        _c("br"),
        _vm._v(" 免费获取医学专家精彩课程"),
        _c("br")
      ]),
      _c("div", [
        _c("img", { attrs: { src: require("../../assets/images/code.png") } })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }