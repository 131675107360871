<template>
  <div class="QRcode">
      <div class="title">扫码/长按添加：官方企业微信</div>
      <div>
        了解更多带病保险知识<br>
        加入病友互动群交流经验<br>
        免费获取医学专家精彩课程<br>
      </div>
      <div><img src="../../assets/images/code.png"></div>
  </div>
</template>

<script>



export default {
  name: 'QRcode',
  data() {
    return {
    }
  },
  props: {

  },
  components: {

  },
  created() {

  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.QRcode{
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  background: url("../../assets/images/codebg.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
  color: #214054;
  font-size: .24rem;
  .title{
    font-size: .28rem;
    font-weight: bold;
    margin: 2.5rem 0 .5rem 0;
  }
  img{
    width: 4.46rem;
    height: 4.46rem;
    margin-top: 1.05rem;
  }
}
</style>

